@font-face {
	font-family: Inter;
	src: url(./assets/Kufam-VariableFont_wght.ttf);}
@font-face {
	font-family: Komikax;
	src: url(./assets/KOMIKAX_.ttf);
}
@font-face {
	font-family: Mayeka;
	src: url(./assets/Mayeka\ Regular\ Demo.otf);
}
@font-face {
	font-family: MayekaBOLD;
	src: url(./assets/Mayeka\ Bold\ Demo.otf);
}

.kufam{font-family: Inter;}
.GuideParagraph a{
	color: #576958;
}
h1,h2,h3,p{margin: 0;}
.redtextColor{color:#ff0e00}
.bluetextColor{color:#0007ff}
.App {
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
overflow: hidden;
background-color: #000;
font-family: Inter;
}
.Header{
  width: 100%;
  aspect-ratio: 1.975;
  display: flex;
  flex-direction: row;
  background-image: url(./assets/2.png);
  position: relative;
background-repeat: no-repeat;
background-size: cover;
}
.Logo{
  position: absolute;
  width: 35%;
  top: 20%;
  left: 5%;
  animation: entrance 1.5s ease-in-out 0s 1 normal forwards, pulse 5s ease 2s infinite normal forwards;
}
@keyframes pulse {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.05);
	}

	100% {
		transform: scale(1);
	}
}
@keyframes entrance {
	0% {
		transform: translateY(-250px);
	}

	38% {
		transform: translateY(0);
	}

	55% {
		transform: translateY(-65px);
	}

	72% {
		transform: translateY(0);
	}

	81% {
		transform: translateY(-28px);
	}

	90% {
		transform: translateY(0);
	}

	95% {
		transform: translateY(-8px);
	}

	100% {
		transform: translateY(0);
	}
}

.CAContainer{
	background-color: #fff;
	width: 70%;
	height: 100%;
	color: #000000;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	border-radius: 8vw;
	font-size: 1vw;
	padding: 0 0 0 2%;
}
.CAButton{
	background-color:#888888;
	color: #fff;
	text-transform: uppercase;
	width: 40%;
	display: flex;
	align-items: center;
	height: 100%;
	width: fit-content;
	font-weight: 500;
	font-size: 0.8vw;
	border-radius: 0 25vw 25vw 0;
	padding: 0 2%;
	transition: all 150ms ease-in-out
}
.CAButton:hover{
	cursor: pointer;
	background-color: #999;
}
.RedBioSection{
	width: 100%;
	aspect-ratio: 2.5;
	background-color: #ffa0a0;
	background-image: url('./assets/RedArt1.png'), url('./assets/RedBGArt1.png');
	background-size: contain, cover;
	background-position: bottom right, center;
	background-repeat: no-repeat;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-bottom: #000 0.625vw solid;
}
.BlueBioSection{
	width: 100%;
	aspect-ratio: 2.5;
	background-color: #1790ff;
	background-image: url('./assets/BlueArt1.png'), url('./assets/BlueBGArt1.png');
	background-size: contain, cover;
	background-position: bottom left, center;
	background-repeat: no-repeat;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-bottom: #000 0.625vw solid;
}
.ColorTextPart{
	margin-left: 10%;
	margin-top: -3%;
	width: 50%;
	display: flex;
	flex-direction: column;
}
.marginl40{
	margin-left: 40%;

}
.ColorTitle{
	margin: 0;
	font-weight: 800;
	font-size: 8vw;
	text-wrap: nowrap;
	text-transform: uppercase;
	font-family: Komikax;
	margin-bottom: -0.5vw;
	text-shadow:0.2vw 0 #fff,    /* Right shadow */-0.2vw 0 #fff,   /* Left shadow */0 0.2vw #fff,    /* Bottom shadow */0 -0.2vw #fff,   /* Top shadow */0.2vw 0.2vw #fff, /* Bottom-right shadow */-0.2vw -0.2vw #fff, /* Top-left shadow */
    -0.2vw 0.2vw #fff, /* Bottom-left shadow */0.2vw -0.2vw #fff, /* Top-right shadow */rgba(0, 0, 0, 0.09) 0 0.104vw 0.052vw, rgba(0, 0, 0, 0.09) 0 0.208vw 0.104vw, rgba(0, 0, 0, 0.09) 0 0.417vw 0.208vw, rgba(0, 0, 0, 0.09) 0 0.833vw 0.417vw, rgba(0, 0, 0, 0.09) 0 1.667vw 0.833vw;
}
.ColorParagraph{
	font-size: 1.589vw;
	margin: 0;
}
.Actions{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 1vw;
}
.BuyButton{
	color: #000;
	background-color: #fff;
	border: #000 0.2vw solid;
	font-size: 2vw;
	font-weight: 800;
	padding: 0.5vw;
	width: fit-content;
	transition: all ease-in-out 150ms;

}
.BuyButton:hover{
	cursor: pointer;
	scale: 1.1;
}
.ticker-container {
	width: 100%;
	overflow: hidden;
	background-image: linear-gradient(to right, #ffffff, #e9e9e9);
	color: #000;
	border-bottom: #000 0.625vw solid;
	border-top: #000 0.625vw solid;
	padding: 0.6vw 0;
	font-size: 2vw;
  }
  .ticker {
  display: flex;
  white-space: nowrap;
  /* Prevent the text from wrapping */
}
.ticker-text {
  display: inline-block;
  padding-left: 00%;
  animation: ticker 600s linear infinite;
  /* Apply the scrolling animation */
}

@keyframes ticker {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}
.ExchangeContainer{
	width: 100%;
	padding: 1vw 0;
	background: #9df7ff;
	background-image: url('./assets/3\ B.png'),url('./assets/3\ R.png'),url(./assets/3\ Clouds.png);
	background-position: top left, top right, 100% 0%;
	background-size: 100%,20vw,95%;
	background-repeat: no-repeat,no-repeat,repeat-x;
	display: flex;
	flex-direction: column;
	align-items: center;
	animation: cloudmovement 30s linear 0s infinite ;
	border-bottom: #000 0.625vw solid;

}
@keyframes cloudmovement {
	0%{
		background-position: top left, top right, 100% 0%;
	}
	50%{		
		background-position: top left, top right, -50% 0%;
	}
	100%{
		background-position: top left, top right, 100% 0%;
	}
}
.ExchangeContainer h1{
	font-size: 5.208vw;
	font-weight: 800;
	text-transform: uppercase;
	font-family: Komikax;
	text-shadow:0.2vw 0 #fff,-0.2vw 0 #fff, 0 0.2vw #fff,0 -0.2vw #fff,0.2vw 0.2vw #fff,-0.2vw -0.2vw #fff,-0.2vw 0.2vw #fff,0.2vw -0.2vw #fff,rgba(0, 0, 0, 0.09) 0 0.104vw 0.052vw, rgba(0, 0, 0, 0.09) 0 0.208vw 0.104vw, rgba(0, 0, 0, 0.09) 0 0.417vw 0.208vw, rgba(0, 0, 0, 0.09) 0 0.833vw 0.417vw, rgba(0, 0, 0, 0.09) 0 1.667vw 0.833vw;

}
.ExchangeContainer h3{
	font-size: 1.111vw;
	font-weight: 500;
}
.ExchangeContainer p{
	background-color: #9df7ff;
	width: fit-content;
	font-size: 0.833vw;
}
.ExchangeItemContainer{
	width: 60%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 2vw;
	justify-content:space-around;
	padding: 2vw 0;
}
.ExchangeItem{
	width: 30%;
	aspect-ratio: 2.5;
	border: 0.175vw hsla(0, 0%, 0%, 0.75) solid;
	background-image: linear-gradient(45deg, rgba(48, 170, 181, 0.5),rgba(27, 80, 141, 0.5));
	border-radius: 1vw;
	backdrop-filter: blur(20px);
	transition: all 300ms linear;
}
.ExchangeItem:hover{
	scale: 1.1;
}
.ExchangeItem img{
	width: 100%;
	height: 100%;
	border-radius: 1vw;
}
.GuideContainer{
	width: 95%;
	display: flex;
	flex-direction: column;
	border-bottom: #000 0.625vw solid;
	padding: 1vw 0vw 1vw 5%;
	background-color: #fff;
	background-image: url('./assets/RedArt2.png');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: 110% 100%
}
.GuideContainer h1{
	font-size: 5.208vw;
	font-weight: 800;
	text-transform: uppercase;
	font-family: Komikax;
	text-shadow:0.2vw 0 #fff,    /* Right shadow */-0.2vw 0 #fff,   /* Left shadow */0 0.2vw #fff,    /* Bottom shadow */0 -0.2vw #fff,   /* Top shadow */0.2vw 0.2vw #fff, /* Bottom-right shadow */-0.2vw -0.2vw #fff, /* Top-left shadow */
    -0.2vw 0.2vw #fff, /* Bottom-left shadow */0.2vw -0.2vw #fff, /* Top-right shadow */rgba(0, 0, 0, 0.09) 0 0.104vw 0.052vw, rgba(0, 0, 0, 0.09) 0 0.208vw 0.104vw, rgba(0, 0, 0, 0.09) 0 0.417vw 0.208vw, rgba(0, 0, 0, 0.09) 0 0.833vw 0.417vw, rgba(0, 0, 0, 0.09) 0 1.667vw 0.833vw;
}
.GuideContainer h3{
	font-size: 1.111vw;
	font-weight: 500;
}
.GuideBox{
	width: 80%;
	display: flex;
	flex-direction: column;
	}
.GuideItem{
	width: 50%;
	color: #fff;
	padding: 1vw 0;
	transition: all 200ms ease-in-out;
}
.GuideItem:hover{
	cursor: default;
	scale: 1.1;
}
.seperator{
	width: 50%;
	height: 0.2vw;
	background-color: #576958;
	border-radius: 1vw;
}
.GuideTitle{
	font-size: 2.431vw;
	color: #000;
	font-weight: 800;
	font-family: Inter;

}
.GuideParagraph{
	color: #606060;
	font-family: Mayeka;
	font-size: 0.972vw;
	font-weight: 400;
}
.GoldHighlight{
	color: #576958;
}
.SocialsContainera{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1vw 0;
	background-image: url('./assets/1.png'),linear-gradient(45deg,#048d97,#00ddff);
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: center bottom,center;
}
.SocialsContainer{
	width: 100%;
	aspect-ratio: 2.8;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1vw 0;
	background-color: #fff;
	background-image: url(./assets/Header.png);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}
.SocialsContainer h1{
	font-size: 5.208vw;
	font-weight: 800;
	text-transform: uppercase;
	font-family: Komikax;
	text-shadow:0.2vw 0 #fff,    /* Right shadow */-0.2vw 0 #fff,   /* Left shadow */0 0.2vw #fff,    /* Bottom shadow */0 -0.2vw #fff,   /* Top shadow */0.2vw 0.2vw #fff, /* Bottom-right shadow */-0.2vw -0.2vw #fff, /* Top-left shadow */
    -0.2vw 0.2vw #fff, /* Bottom-left shadow */0.2vw -0.2vw #fff, /* Top-right shadow */rgba(0, 0, 0, 0.09) 0 0.104vw 0.052vw, rgba(0, 0, 0, 0.09) 0 0.208vw 0.104vw, rgba(0, 0, 0, 0.09) 0 0.417vw 0.208vw, rgba(0, 0, 0, 0.09) 0 0.833vw 0.417vw, rgba(0, 0, 0, 0.09) 0 1.667vw 0.833vw;
}
.Footer h3{
	color: #fff;
	font-size: 1.111vw;
	font-weight: 500;
}
.SocialsIcons{
	display: flex;
	flex-direction: row;
	width: 20%;
	justify-content: space-around;
	padding: 2vw 0;
}
.SocialIcon{
	width: 7vw;
	aspect-ratio: 1;
	border-radius: 1vw;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border: #fff 0.2vw solid;
	transition: all 150ms ease-in-out;
	margin: 4vw 0;
}
.XLogo{
	background-image: url('./assets/XLogo.png');
}
.TelegramLogo{
	background-image: url('./assets/TelegramLogo.png');

}
.SocialIcon:hover{
	rotate: 2deg;
	scale: 1.2;
	cursor: pointer;
	filter: contrast(125%) brightness(103%);
	box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

}
.SocialIcon:active{
	rotate: 4deg;
	scale: 1.1;
	cursor: pointer;
	filter: contrast(175%) brightness(103%);
	box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}
.Footer{
	width: 100%;
	padding: 0.5vw 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}